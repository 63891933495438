<template>
  <div class="login-wrapper">
    <div class="login-content">
      <div class="login-logo">
        <logo />
      </div>

      <form v-if="!message" action="" @submit.prevent="submit">
        <div class="auth_block">
          <div class="login-form">
            <form-group label="Password">
              <password-validation-helper :show="showPasswordHelper" :value="form.password" placement="right-start">
                <input type="password"
                       v-model="form.password"
                       class="form-control"
                       :class="{valid:form.password}"
                       @focusin="showPasswordHelper = true"
                       @focusout="showPasswordHelper = false"
                >
              </password-validation-helper>
            </form-group>

            <form-group label="Password repeat" style="margin-top: 20px;">
              <input type="password" v-model="form.password_repeat" class="form-control" :class="{valid:form.password_repeat}">
            </form-group>
          </div>
        </div>

        <button v-show="!message" type="submit" class="default-button login-button" style="margin-top: 30px;">
          Submit
        </button>

        <div v-show="errors" class="login-button default-button error" style="margin-top: 20px; display: block;">
          {{ getFirstError() }}
        </div>
      </form>

      <div v-show="message" class="success-block">
        <div class="success-block-message">
          {{ message }}
        </div>
        <router-link to="/" class="login-button default-button">
          Go back to login
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from "@/libs/Logo";
import FormGroup from '../libs/forms/FormGroup'
import PasswordValidationHelper from "@/libs/PasswordValidationHelper";

export default {
  name: 'ResetPassword',
  components: {
    PasswordValidationHelper,
    FormGroup,
    Logo
  },
  data () {
    return {
      form: {
        password: '',
        password_repeat: ''
      },

      showPasswordHelper: false,

      errors: null,
      message: ''
    }
  },
  computed: {
    formData () {
      return {
        password: this.form.password,
        password_repeat: this.form.password_repeat
      }
    }
  },
  methods: {
    submit () {
      this.$sdk.auth.requestResetPasswordToken(this.$route.params.token, this.formData).then(response => {
        if (Object.prototype.hasOwnProperty.call(response.data, 'success')) {
          this.errors = null
          this.message = response.data.message

          setTimeout(() => {
            this.$router.push({ name: 'login' })
          }, 10000)
        } else {
          this.errors = response.data
        }
      }).catch((error) => {
        if (error.response) {
          this.errors = [[error.response.data.message]]
        } else {
          this.errors = [[error.message]]
        }
      })
    },

    /**
     * Display first message in to summary block
     */
    getFirstError () {
      for (const key in this.errors) {
        const value = this.errors[key][0]
        if (value.length > 0) {
          return value
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.success-block {
  &-message {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 1.2em;
    margin-bottom: 30px;
  }
}
</style>
